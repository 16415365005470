//$font-family-base:"Source Sans Pro";
$font-size-base:              1rem !default;
$body-color: #000;
$primary:orange;
$light: #fbfbfb;
$h1-font-size:                $font-size-base * 3.1 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;
$headings-line-height:1.5;

/* make the customizations */
$theme-colors: (
  'primary': orange,
  'light':#fbfbfb,
  'danger': red,
);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

@import 'custom.scss'