a{
    text-decoration: none;
}
@media (max-width: 991px) {
.carousel-caption{
    position: relative;
    left: 0;
    right: 0;
    padding: 30px 15px;
}
}
@media (min-width: 992px) {
.carousel-caption{
    top: 50%;
    bottom: auto;
    padding: 0;
    transform: translateY(-50%);
    text-align: left;
    width: 40%;
    left: 10%;
}
}
.nav-link{
    border-bottom: 3px solid transparent;
    
    &.active:not(.deactive){
        border-color: $primary;
    }
}

@media (max-width: 991px) {
.navbar-collapse{
    position: absolute;
    top: 100px;
    background: $light;
    z-index: 99;
    left: 0px;
    right: 0px;
    padding: 0px 20px;
}
}